export const GadgetNavigateEvent = "gadget:navigate";
export const GadgetNavigatedEvent = "gadget:navigated";
export const GadgetNavigateParentFrameEvent = "gadget:outernavigate";

export type GadgetNavigatePostMessageCommand =
  | {
      type: typeof GadgetNavigateEvent;
      action: "back";
    }
  | {
      type: typeof GadgetNavigateEvent;
      action: "forward";
    }
  | {
      type: typeof GadgetNavigateEvent;
      action: "refresh";
    }
  | {
      type: typeof GadgetNavigateEvent;
      action: "navigate";
      path: string;
    }
  | {
      type: typeof GadgetNavigateEvent;
      action: "pushState";
      state?: Record<string, any> | undefined;
      path?: string | undefined;
    };

interface GadgetNavigatedPostMessageData {
  type: typeof GadgetNavigatedEvent;
  url: string;
  canGoBack: boolean;
  canGoForward: boolean;
}

function isGadgetNavigatePostMessageCommand(message: any): message is GadgetNavigatePostMessageCommand {
  return message && typeof message === "object" && message.type === GadgetNavigateEvent;
}

function navigateGadgetParentFrame(destination: string) {
  if (typeof window !== "undefined" && window.parent) {
    window.parent.postMessage({ type: GadgetNavigateParentFrameEvent, destination }, "*");
  }
}

export const mountGadgetIframeNavigation = (getConfig: () => { environmentName: string } | undefined) => {
  (window as any).navigateGadgetParentFrame = navigateGadgetParentFrame;

  // set up a history index for the outer editor to track the current history index
  console.debug("[gadget iframe] setting up history index");
  window.history.replaceState({ gadgetIdx: 0, gadgetHistoryLength: 0, ...(window.history.state ?? {}) }, "");

  let gadgetHistoryLength = window.history.state.gadgetHistoryLength;
  console.debug("[gadget iframe] current gadgetHistoryLength", gadgetHistoryLength);

  const sendNavigationState = () => {
    const eventData: GadgetNavigatedPostMessageData = {
      type: GadgetNavigatedEvent,
      url: window.location.href,
      canGoBack: window.history.state.gadgetIdx > 0,
      canGoForward: window.history.state.gadgetIdx < window.history.state.gadgetHistoryLength,
    };
    console.debug("[gadget iframe] sending navigation state", {
      eventData,
      gadgetIdx: window.history.state.gadgetIdx,
      gadgetHistoryLength: window.history.state.gadgetHistoryLength,
    });
    window.parent.postMessage(eventData, "*");
  };

  // Send initial navigation state
  sendNavigationState();

  const originalPushState = window.history.pushState;
  window.history.pushState = function (...args) {
    gadgetHistoryLength++;
    if (args[0]) {
      args[0].gadgetIdx = gadgetHistoryLength;
      args[0].gadgetHistoryLength = gadgetHistoryLength;
    } else {
      args[0] = { gadgetIdx: gadgetHistoryLength, gadgetHistoryLength: gadgetHistoryLength };
    }
    console.debug("[gadget iframe] pushstate event", args);
    originalPushState.apply(this, args);
    sendNavigationState();
  };

  const handleNavigationEvent = (event: MessageEvent) => {
    if (!event.data || typeof event.data !== "object") return;

    if (!isGadgetNavigatePostMessageCommand(event.data)) return;

    console.debug("[gadget iframe] navigation event", event);

    switch (event.data.action) {
      case "back":
        window.history.back();
        break;
      case "forward":
        window.history.forward();
        break;
      case "refresh":
        window.location.reload();
        break;
      case "navigate":
        window.location.href = event.data.path;
        break;
      case "pushState":
        window.history.pushState(event.data.state, "", event.data.path);
        break;
    }
  };
  window.addEventListener("message", handleNavigationEvent);

  const handlePopState = () => {
    console.debug("[gadget iframe] popstate event", event);
    window.history.replaceState({ ...(window.history.state ?? {}), gadgetHistoryLength: gadgetHistoryLength }, "");
    sendNavigationState();
  };
  window.addEventListener("popstate", handlePopState);

  // Hijack links to the app that open in a new tab when we're in embedded preview and propagate to the parent to navigate
  const handleClick = (event: MouseEvent) => {
    const link = (event.target as HTMLElement)?.closest("a");
    const targetUrl = link ? new URL(link.href) : null;

    // Only hijack if the link opening in a new tab
    if (link?.target !== "_blank") return;

    const config = getConfig();

    // Only hijack if the link is to the app
    if (!config || targetUrl?.hostname !== window.location.hostname || !targetUrl?.pathname.includes(`/edit/`)) return;

    // Don't hijack if we're within the Shopify admin iframe
    const shopify = (window as any).shopify;
    if (shopify && !shopify.isGadget) return;

    event.preventDefault();
    event.stopPropagation();

    const url = link.href.replace(`--${config.environmentName}`, "");

    console.debug("[gadget iframe] navigating is outer frame", { url });
    window.parent.postMessage({ type: "gadget:outernavigate", destination: url }, "*");
  };
  window.addEventListener("click", handleClick);

  return () => {
    window.removeEventListener("message", handleNavigationEvent);
    window.removeEventListener("popstate", handlePopState);
    window.removeEventListener("click", handleClick);
    (window as any).navigateGadgetParentFrame = undefined;
    window.history.pushState = originalPushState;
  };
};
